import React,{useState, useEffect, useRef} from 'react'
import AnimatedClock from '../../assets/images/animated_clock'
import {getLocationData} from '../../location_data'
import {navigate} from 'gatsby'
import './style.scss'
import { isMobile } from 'react-device-detect'
const ContactYouBackPopup = ({timevalue,popupshow, handlePopup,dateed}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const [formsubmitted,setFormsubmitted] = useState(false)
    const [emailupdated,setEmailupdated] = useState(false)
    const [focuss,setFocuss] = useState(false)
    const [qdata,setQdata] = useState({})
    const wr = useRef(null)
    const wr1 = useRef(null)
    const wr2 = useRef(null)
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
      const handleSubmit=(e)=>{  
        const userrole = []
        const lifecycledata = []
        let expert = null  
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('roleData')){
                const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
                t.forEach(el=>{
                    if(el.checked === true){
                        userrole.push(el.role)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('lifecycledata')){
                const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
                s.forEach(el=>{
                    if(el.checked === true){
                        lifecycledata.push(el.name)
                    }
                })
            }
           
             if(windowGlobal.localStorage.getItem('expert_name')){
              expert = windowGlobal.localStorage.getItem('expert_name')
            }
        }           
            fetch("/", {
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": 'contact_you_back', 
                                ...qdata,
                                "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                                "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                                "Expertise":expert,
                                "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                                "Country":getLocationData('country_name'),
                                "Zip":getLocationData('zipcode'),
                                "User_country":getLocationData('country_name'),
                                "City":getLocationData('city'),
                                "Latitude":getLocationData('latitude'),
                                "Longitude":getLocationData('longitude'),
                                "State":getLocationData('state_prov'),
                             })
              })
                .then(() =>{
                    setFormsubmitted(true)
                    setTimeout(() => {
                    navigate('/thank-you-page')
                }, 1000);})
                .catch(error => alert(error));
                e.preventDefault()
               
         
      }

      const handleInputChnage = (e)=>{   
        setQdata({...qdata,[e.target.name]:e.target.value})
        
    }
   

    useEffect(()=>{  
      
        if(popupshow && !qdata[`quote-select`]){
         
            if(windowGlobal.localStorage.getItem('email') && !focuss && windowGlobal.innerWidth > 1023 && !isMobile){                
                    wr2.current.focus()
                    setFocuss(true)
            }else if(!focuss && windowGlobal.innerWidth > 1023 && !isMobile){           
                    wr1.current.focus()                  
                    setFocuss(true)
            }
            setQdata({...qdata,[wr.current.name]:wr.current.value})
        }
     
        if(window && popupshow && !qdata[`quote-email`] && !qdata[`quote-phone`] && !emailupdated){
          if(localStorage.getItem('email') && localStorage.getItem('number')){
              const em =  localStorage.getItem('email')
              const en =  localStorage.getItem('number')
              setQdata({...qdata,[`quote-email`]:em,[`quote-phone`]:en})
              setEmailupdated(true)
          }else if(localStorage.getItem('email') && !localStorage.getItem('number')){
            const em =  localStorage.getItem('email')
            setQdata({...qdata,[`quote-email`]:em})
            setEmailupdated(true)
        }else if(!localStorage.getItem('email') && localStorage.getItem('number')){
            const en =  localStorage.getItem('number')
            setQdata({...qdata,[`quote-phone`]:en})
            setEmailupdated(true)
        }
        }
      
    },[formsubmitted,popupshow, dateed, qdata])
return(
    <React.Fragment>
       
        <div id="qoutes-form" 
        className={`span12 widget-span widget-type-cell request-quote-popup-layout slide-popup ${popupshow ? `wow show` :null}`}>
        
        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell request-quote-popup">
        
        <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_jinja ">
        
                
        <a to="#" className="close cp14" onClick={()=>handlePopup('close')}></a>
        <h2 className="en-text" style={{display:'inline-block'}}>We are online and <br/> we can contact you back in</h2>
        <h4> 
        <span style={{width:`50px`, display:'inline-block'}}> 
        <AnimatedClock/>
        </span> 
        
        <span id="timer">
        <span className="min">{timevalue.mm}</span>{' '}
        <span className="en-text" style={{display:'inline-block'}}>minutes and</span>{' '}
        <span className="sec">{timevalue.ss}</span>{' '}
        <span className="en-text" style={{display:'inline-block'}}>seconds</span>
        </span>
        
        </h4>
                    <form 
                    id="teach-peter"
                    name="contact_you_back"
                    method="POST"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    >
                    <input type="hidden" name="form-name" value="contact_you_back" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleInputChnage} />
                    </label>
                    </p> 
                      <select name="quote-select" id="quote-select" onChange={handleInputChnage} ref={wr}> 
                      {/* <option>{dateed !==null && dateed[0]}</option>
                      <option>{dateed !==null && dateed[1]}</option>
                      <option>{dateed !==null && dateed[2]}</option>
                      <option>{dateed !==null && dateed[3]}</option>
                      <option>{dateed !==null && dateed[4]}</option> */}
                      {dateed.length !==0 && dateed.map(el=>{return <option>{el}</option>})}
                      </select>  
                      <input type="email" name="quote-email" ref={wr1} id="quoteemail" value={qdata[`quote-email`]} required={true} placeholder="Your email address" onChange={handleInputChnage}/>
                      <input type="text" name="quote-name" ref={wr2} id="quotename" required={true} placeholder="Your first name" onChange={handleInputChnage}/>
                      <input type="tel" name="quote-phone" id="quotetel" value={qdata[`quote-phone`]} required={true} placeholder="Your phone number" onChange={handleInputChnage}/>
                      <p hidden>
                        <input name="User_roles" />
                        <input name="Company_lifecycle" />                 
                        <input name="Expertise" />                   
                        <input name="Language" />
                        <input name="Country" />
                        <input name="Zip" />
                        <input name="User_country" />
                        <input name="City" />
                        <input name="Latitude" />
                        <input name="Longitude" />
                        <input name="State" />
                      </p>
                      <input type="submit" value="Yes I want to make my POC"/>
                      <a href="#" className="close closecss en-text" style={{display:'inline-block'}} onClick={()=>handlePopup('close')}>
                          No thanks I don't need a Proof Of Concept
                          </a> 
                     
                    </form>
                    <div className="thankyouwrapper" style={{display:formsubmitted ? `block` : '' }}>
                      <div className="thankyou">
                        <h4>Thank You.</h4>
                        <p>We will answer as soon as possible.</p>
                      </div>
                    </div> 
                 
                
                  
               </div>
        
        </div>
        </div>
        
        </div>
        </div>
        </div>
        
        </div>
        </React.Fragment>
    )
}

export default ContactYouBackPopup