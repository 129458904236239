import React,{useState, useEffect} from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import LandingHeader from '../header'
import AnimatedClock from '../../assets/images/animated_clock'
import redimage from '../../assets/images/red-image.svg'
import greenimage from '../../assets/images/green-image.svg'
import SlideHomeBoardTable from '../../assets/images/slide-home-board-table'
import ContactYouBackPopup from '../contact-you-back-popup';
import Helmet from 'react-helmet'


const CallUsLandingPage = () =>{
    const [popupshow,setPopupshow] = useState(false)
    const [timevalue,setTimevalue] = useState({mm:3,ss:59})
    const [starttimer,setStarttimer] = useState(false)
    const [dateed, setDateed] = useState([])
        const countdownTimer = () =>{
            let timeval = 239
            const timer = setInterval(() => {
              
                if(timeval>0){
                   timeval = timeval-1
       
                   let divisor_for_minutes = timeval % (60 * 60);
                   let minutes = Math.floor(divisor_for_minutes / 60);
               
                   let divisor_for_seconds = divisor_for_minutes % 60;
                   let seconds = Math.ceil(divisor_for_seconds);
       
                   setTimevalue({mm:minutes,ss:seconds})
                }
                if(timeval === 0){
                    clearInterval(timer)
                }
            }, 1000);
           }
    
        
    const handlePopup = (e)=>{
        if(e === 'open'){            
            setPopupshow(true)
        }else if(e === 'close'){
            setPopupshow(false)
        }else if(e === 'toggle'){
            setPopupshow(!popupshow)
        }
    }
    const handleListDate = ()=>{
        const srr =[]
        if(dateed.length === 0){      
            const d = new Date()
            const d1 = new Date(d.setMinutes(d.getMinutes() + 10))
            const d2 = new Date(d.setMinutes(d.getMinutes() + 240))
            const d3 = new Date(d.setMinutes(d.getMinutes() + 240))
            const d4 = new Date(d.setMinutes(d.getMinutes() + 240))
            const d5 = new Date(d.setMinutes(d.getMinutes() + 240))

            srr.push(d1.toString())
            srr.push(d2.toString())
            srr.push(d3.toString())
            srr.push(d4.toString())
            srr.push(d5.toString())
            setDateed([...srr])
        
           }
    }
    useEffect(()=>{
        if(dateed.length === 0 && popupshow){       
            handleListDate()
           
         }
        if(!starttimer){
            setStarttimer(true)
            countdownTimer()
        }
    },[popupshow,timevalue])
    
    return(
        <div className="body-container-wrapper">
            <Helmet defer={false}>
                <title>Call us | JDSolutions</title>
                <meta property="og:title" content={`Call us | JDSolutions`} />
                <meta property="og:type" content="page" />
                {/* <meta property="og:image" content={bigbanner} />  */}
            </Helmet>
        <div className="body-container container-fluid">
        <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
        <div className="row-fluid ">
        <ReactFullpage
                scrollingSpeed = {700}
                anchors=  { ['Call-us',
                'Who-is-JDSolutions' ]}
                easing= 'easeInOutCubic'

                render={({ fullpageApi}) => {
                return (
                
                    <ReactFullpage.Wrapper>
                                <Section1 timevalue={timevalue} handlePopup={handlePopup}/>
                                <Section2 timevalue={timevalue} handlePopup={handlePopup}/>
                    </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
        </div>
        </div>
        <ContactYouBackPopup timevalue={timevalue} popupshow={popupshow} handlePopup={handlePopup} dateed={dateed}/>
        </div>
            
        )
}
export default CallUsLandingPage


const Section1 = ({timevalue, handlePopup}) =>{
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-6  section0 section " 
        id="section0" style={{backgroundColor: '#eaeaea'}}>
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell ">
        <LandingHeader/>
        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
        <div className="row-fluid ">
            <div className="content-wrapper">
            <div className="span12 widget-span widget-type-cell ">
            <div className="row-fluid-wrapper row-depth-1 row-number-10 ">


<div className="row-fluid ">
<div className="span12 widget-span widget-type-raw_html content-module">
<div className="cell-wrapper layout-widget-wrapper">

<h4 className="en-text" style={{display:'inline-block'}}>We can call you back</h4>

<h3>
<span>
<AnimatedClock/>
</span>                               
<span id="timer1">
<span className="min">{timevalue.mm}</span>{' '}
<span className="en-text" style={{display:'inline-block'}}>minutes and</span>{' '}
<span className="sec">{timevalue.ss}</span>{' '}
<span className="en-text" style={{display:'inline-block'}}>seconds</span>
</span>
</h3>
<h2 className="en-text" style={{display:'inline-block'}}>
How to create a {' '}
<strong>
    Proof Of Concept without
</strong> {' '}
writing a single line of {' '}
<strong>
    code?
</strong>
</h2>

<a href="#" className="banner-btn header-contact-btn" onClick={()=>handlePopup('open')}>
<span className="en-text" style={{display:'inline-block'}}>
    Yes, I want to make my POC
</span>
</a>
</div>
</div>
</div>
</div>
<div className="row-fluid-wrapper row-depth-1 row-number-11 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell bottom-svgs">
<div className="row-fluid-wrapper row-depth-2 row-number-1 ">
<div className="row-fluid ">
<div className="first-svg svg">
<div className="span12 widget-span widget-type-raw_html ">
<div className="cell-wrapper layout-widget-wrapper">
<img src={redimage} alt="first human image"/>
</div>
</div>
</div>
</div>
</div>
<div className="row-fluid-wrapper row-depth-2 row-number-2 ">
<div className="row-fluid ">
<div className="second-svg svg">
<div className="span12 widget-span widget-type-raw_jinja ">
<img src={greenimage} alt="second human image"/>
    </div>
    </div>
    </div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
    )
}

const Section2 = ({timevalue,handlePopup}) =>{
    return(
        <div className="row-fluid-wrapper row-depth-1 row-number-1  section1 section" 
        id="section1">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell ">

        <div className="row-fluid-wrapper row-depth-1 row-number-2  inherited_property_left">
        <div className="row-fluid ">

        <div className="l-sec-2-cont section-left">
        <div className="span12 widget-span widget-type-raw_jinja ">
        <div className="en-text" style={{display:'inline-block'}}>
	    <h2>Who is JDSolutions</h2>
	    <p>JDSolutions is a fast growing web agency with top-notch developers based in the silicon valley of Poland – Wroclaw. </p>
	    <p>We are a professional nearshoring software house with an awesome motivated team all working from within the same office. Within our office we have different developers &amp; mini-teams who are 100% committed to a partner from abroad.</p>
	    <h4>We can call you back</h4>
</div>


<h3>
<span>
<AnimatedClock/>
</span>

<span id="timer2">
    <span className="min">{timevalue.mm}</span>{' '}
<span className="en-text" style={{display:'inline-block'}}>minutes and</span>{' '}
    <span className="sec">{timevalue.ss}</span> {' '}
<span className="en-text" style={{display:'inline-block'}}>seconds</span>
  </span>

</h3>
<a to="#" 
className="banner-btn header-contact-btn en-text" 
onClick={()=>handlePopup('open')}
style={{display:'inline-block'}}>
    Yes, I want to make my POC
    </a>
    </div>
</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-3  inherited_property_right">
<div className="row-fluid ">
<div className="l-sec-2-svg section-right">
<div className="span12 widget-span widget-type-raw_jinja ">
<SlideHomeBoardTable/>
</div>
</div>
</div>
</div>


</div>
</div>
</div>
    )
}