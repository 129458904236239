import React from 'react'
import './style.scss'
const AnimatedClock = () =>{
    return(
        <svg className="lp-clock-svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="16193.089 4683.042 62.141 62.141">

        <g id="Group_673" data-name="Group 673" transform="translate(15447 4369.41)">
        <g id="group-266" transform="translate(746.089 313.632)">
        <ellipse id="ellipse-13" className="cls-1-r" cx="31.07" cy="31.07" rx="31.07" ry="31.07"></ellipse>
        <g id="ellipse-13-2" data-name="ellipse-13" className="cls-2-r" transform="translate(4.616 3.551)">
        <ellipse className="cls-4-r" cx="26.454" cy="26.454" rx="26.454" ry="26.454"></ellipse>
        <ellipse className="cls-5-r" cx="26.454" cy="26.454" rx="24.954" ry="24.954"></ellipse>
        </g>
        </g>
        <g id="group-76" transform="translate(775.384 329.078)">
        <line id="line-17" className="cls-3-r clock_mins_lp" y2="16.865" transform="translate(0.823)"></line>
        </g>
        </g>
        </svg>
    )
}

export default AnimatedClock;